<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1  -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            REGISTER
          </h2>
        </b-link>

        <b-card-title class="mb-1" />
        <b-card-text class="mb-2" />

        <!-- form -->
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="signup"
        >

          <!-- userId -->
          <b-form-group
            label="ID"
            label-for="userId"
          >
            <b-form-input
              id="userId"
              v-model="registData.userid"
              name="register-userId"
              placeholder="ID"
            />
          </b-form-group>

          <!-- password -->
          <b-form-group
            label="Password"
            label-for="password"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="password"
                v-model="registData.password"
                :type="passwordFieldType"
                class="form-control-merge"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <!-- nickname -->
          <b-form-group
            label="Nickname"
            label-for="nickname"
          >
            <b-form-input
              id="nickname"
              v-model="registData.nickname"
              name="register-nickname"
              placeholder="johndoe"
            />
          </b-form-group>

          <!-- email -->
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="registData.email"
              name="register-email"
              placeholder="john@example.com"
            />
          </b-form-group>

          <!-- refer code -->
          <b-form-group
            label="Refer Code"
            label-for="refercode"
          >
            <b-form-input
              id="recommendNickname"
              v-model="registData.recommendNickname"
              name="register-recommendNickname"
              placeholder="johndoe"
            />
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
          >
            Sign up
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import _ from 'lodash'

import { SIGNUP } from '@/store/auth/action'
import { createNamespacedHelpers } from 'vuex'

const authStore = createNamespacedHelpers('authStore')

export default {
  name: 'Signup',

  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      registData: {
        site: null,
        userid: null,
        password: null,
        nickname: null,
        email: null,
        recommendNickname: null,
        enrollIp: null,
      },

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.registData.site = this.$site
    this.registData.enrollIp = localStorage.getItem('ip')
  },
  methods: {
    ...authStore.mapActions({
      $signup: SIGNUP,
    }),
    signup() {
      this.submit()
    },

    submit() {
      Swal.fire({
        title: '가입 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes()
        }
      })
    },

    async confirmYes() {
      await this.$signup({
        ...this.registData,
      })
        .then(res => {
          switch (res) {
            case 1:
              Swal.fire({
                title: '완료 되었습니다.',
                icon: 'success',
                timer: 5000,
                background: 'rgb(3,11,37)',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                this.reset()
                this.$router.push({ name: 'login' })
              })
              break
            case 0:
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: '이미 등록된 정보가 있습니다. ',
                  icon: 'EditIcon',
                  variant: 'error',
                },
              })
              break
            case -99:
            default:
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: '등록실패!! (관리자에게 문의바랍니다.)',
                  icon: 'EditIcon',
                  variant: 'error',
                },
              })
              break
          }
        })
    },

    reset() {
      this.registData.userid = null
      this.registData.password = null
      this.registData.nickname = null
      this.registData.email = null
      this.registData.recommendNickname = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
